<template>
  <div class="signupPage bgfff">
    <div class="app h5MaxWidth">
      <div class="main">
        <div class="signupBox h5MaxWidth display alignCenter">
          <div class="signup forms">
            <div class="topArea">
              <div class="logo"><img src="./../images/logo.svg" alt="" /></div>
              <div class="greetings"></div>
            </div>
            <form id="signup" action="#" method="post" onsubmit="">
              <ul>
                <li class="areaCode">
                  <div class="inputArea display alignCenter">
                    <div class="trueInpt display alignCenter flex1">
                      <p id="areaCode"></p>
                      <input
                        type="number"
                        name="mobile"
                        id="mobile"
                        class="mobile fillArea flex1"
                        placeholder="+91"
                        maxlength="11"
                        size="11"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="inputArea display alignCenter">
                    <div class="trueInpt display alignCenter flex1">
                      <input
                        type="password"
                        name="login_password"
                        id="login_password"
                        placeholder="Login password"
                        class="password fillArea eyeClose flex1"
                      />
                      <div
                        id="eye"
                        class="eye secret rightSideBox display alignCenter"
                      >
                        <i class="r iconfont icon-eye"></i>
                        <i class="s iconfont icon-eye-slash"></i>
                      </div>
                    </div>
                  </div>
                </li>
                <li id="invitationCodeBox" style="display: none">
                  <div class="inputArea display alignCenter">
                    <div class="trueInpt display alignCenter flex1">
                      <input
                        type="text"
                        name="invitationCode"
                        id="invitationCode"
                        class="invitationCode fillArea flex1"
                        placeholder="Invitation code"
                        maxlength="15"
                        size="15"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="inputArea display alignCenter">
                    <div class="trueInpt display alignCenter flex1">
                      <input
                        type="text"
                        name="verificationCode "
                        id="verificationCode"
                        class="checkCode fillArea flex1"
                        placeholder="Verification code (OTP)"
                        maxlength="10"
                        size="10"
                      />
                      <div
                        id="getCode"
                        class="getCode rightSideBox display alignCenter"
                        @click="getCode()"
                      >
                        <p>Send</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="actionBar display alignCenter justifyDetween">
                <div class="goLogin" @click="goLogin()">SIGN IN</div>
                <div
                  class="signInBtn clickBtn radius50P display alignCenter justifyCenter"
                  @click="register()"
                >
                  <p>Sign up now</p>
                  <i class="iconfont icon-arrow-right-3"></i>
                </div>
              </div>
            </form>
          </div>
          <div class="burelage">
            <img src="./../images/pexels-photo-6781340.jpeg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    goLogin() {
      this.$router.push("/login");
    },
    getCode() {
      var no = $.trim($("#mobile").val());
      this.securityCode(no, 1);
    },
    securityCode(mobile, sms_type) {
      var count = 180;
      var interValObj;
      var curCount;
      if (curCount > 0) {
        return false;
      }

      curCount = count;

      if (mobile !== "" && sms_type !== "") {
        this.getAjax(
          "api/login/sendCode",
          {
            type: 0,
            mobile,
          },
          "post"
        ).then((res) => {
          if (res.code === 200) {
            this.tips("send!");
          } else {
            this.toast("", res.msg, "tips", 2000, 1);
          }
        });
      } else {
        this.toast("", "Fail to send verification code.", "noCode", 2000, 1);
      }
    },
    register() {
      var mobile = $("#mobile").val();
      var passWord = $("#login_password").val();
      var invitationCode = $("#invitationCode").val();
      var verificationCode = $("#verificationCode").val();

      if (mobile == "") {
        this.toast("", "Not a valid phone number.", "mobile", 2000, 1);
        $("#mobile").focus();
        return false;
      }

      if (passWord == "") {
        this.toast("", "password can not be empty.", "password", 2000, 1);
        $("#login_password").focus();
        return false;
      }

      if (invitationCode == "") {
        this.toast(
          "",
          "Please enter the invitation code.",
          "invitationCode",
          2000,
          1
        );
        $("#invitationCode").focus();
        return false;
      }

      if (verificationCode == "") {
        this.toast(
          "",
          "Verification code (OTP) can not be empty.",
          "verificationCode",
          2000,
          1
        );
        $("#inviteCode").focus();
        return false;
      }

      this.getAjax("api/login/register", {
        mobile: mobile,
        password: passWord,
        inviteCode: invitationCode,
        code:verificationCode,
      },'post').then(res=>{
        if (res.code === 200) {
            this.tips("success!");
          } else {
            this.toast("", res.msg, "tips", 2000, 1);
          }
      });
    },
  },
  mounted() {
    function GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return r[2];
      return null;
    }
    $(document).ready(function () {
      var invitation_code = GetQueryString("r");

      if (invitation_code == null) {
        invitation_code = localStorage.getItem("invitation_code");
      }

      if (invitation_code != null && invitation_code.length === 5) {
        localStorage.setItem("invitation_code", invitation_code);
        $("#invitationCode").val(invitation_code);
        $("#invitationCode").prop("readonly", true);
        // $("#invitationCode").parent().addClass('readonlyInput');
        $("#invitationCodeBox").hide();
      } else {
        $("#invitationCodeBox").show();
      }

      $("#login_password").togglePassword({
        el: "#eye",
      });
    });
  },
};
</script>

<style scoped lang="less">
</style>
